export default {
    mobileSmall: 14,
    mobileBody: 14,
    tabletBody: 16,
    desktopBody: 16,
    eventTitle: 20,
    eventSubTitle: 18,
    big1: 16,
    mobileHeading2: 18,
    tabletHeading2: 22,
    desktopHeading2: 26,
    button: 16
}
