import React from "react";
import '../../../App.css'
import font from "../../../app/config/font";
export const Privacy = () => {
  return (
    <div className="py-4 px-2">
      <h2 className="py-4">Privacy Policy</h2>
      <p style={{ fontSize: window.innerWidth > 1600 ? font.desktopBody : (window.innerWidth > 1200 && window.innerWidth < 1601 ) ? font.desktopBody : window.innerWidth < 600 ? font.mobileBody : font.tabletBody, lineHeight: window.innerWidth < 600 ? "22px" : "26px"}}>
        The National University Cancer Institute, Singapore (NCIS) values your privacy and the confidentiality of the information you choose to share with us. We want you to visit our web sites without being concerned that others will have access to your confidential information. This statement pertains only to our web sites.
        <br />

        In almost all cases, you can visit our web sites without sharing any personal information about yourself. The only information we learn about you is the information you voluntarily provide us. If you choose to send us an e-mail for which you provide us with personally identifiable data, we may use the necessary data to serve you in a most efficient and effective way.
        <br />

        We will NOT share your personal data with external entities, except where such entities have been authorised to carry out specific services. We will retain your personal data only as necessary for the effective delivery of public services to you.
        <br />

        Please take a few minutes to read our complete policy. This policy may change from time to time. Please review it periodically.
        <br />
        To safeguard your personal data, all electronic storage and transmission of personal data are secured with appropriate security technologies.
        <br />
        This site may contain links to external sites whose data protection and privacy practices may differ from ours. We are not responsible for the content and privacy practices of these other websites and encourage you to consult the privacy notices of those sites.
        <br />
        This statement is not intended to, and does not create, any contractual or other legal rights on behalf of any party.
      </p>
    </div>
  );
};
