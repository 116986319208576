import React from "react";
import { Base_Url, webHost, webHostUi } from "../../routes/Base_Url";

const ViewEmail = (props) => {
  const contentHtml1 = `
  <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" style="background-color:#ffffff; padding-top:10px; max-width: 600px;">
      <tbody>
        <tr>
          <td align="center" style="height:auto;">
            <p style="width:600px;height:auto;position:relative">
              <p style="width: 600px;height: 600px; background-image: url(${webHost}/${window.location.href.split("/")[4]}); box-shadow: 0px 0px 8px 1px #00000033; margin-top: 20px;background-repeat: no-repeat;background-size: cover;">                      
              </p>
            </p>
          </td>
        </tr>
        <tr>
          <td valign="top" style="padding:15px 50px 10px 50px; line-height:0px;">
            <p style="font-family: Open Sans, sans-serif; text-align: center; color: #281f57;font-size: 16px;line-height:25px; margin-bottom:8px"><b>You have received a pledge to raise the awareness for cancer. Since 2018, the NCIS Ribbon Challenge has been raising awareness and public education on cancer. You can join in the <span style="color: #fd794f;">NCIS Ribbon Challenge 2021</span> too, by clicking on the link and pledging a ribbon to a loved one.</b></p>
                            
        </td>
        </tr><tr align="center" valign="top" style="padding:5px; border:0px; line-height:0px;">
          <td align="center" width="600px" bgcolor="#ffffff" valign="middle" style="padding:10px; border:0px; line-height:0px;">
            <p style="background-color : #fd794f; border-radius: 20px; font-style: normal; font-weight: bold; letter-spacing: normal; text-align: center; padding: 5px 10px 5px 10px; margin: 0; font-size: 17px; line-height: 27px; width: 200px; font-family:Open Sans, sans-serif;" align="center"><a href="https://ncisribbonchallenge.sg/?utm_source=TYEmail&utm_medium=email&utm_campaign=ribbonchallenge2021" style="color:#ffffff; text-decoration:none; vertical-align: middle;" target="_blank">Pledge A Ribbon</a></p><p></p>
          </td>
        </tr>
        <tr>
            <td align="center" bgcolor="#e7e1e7" style="padding-top:10px; padding-bottom: 10px; border:0px; line-height:0;" width="100%" height="30px">
              <p style="font-family:Open Sans, sans-serif; text-align:center; font-size:11px; color:#333; line-height: 20px; vertical-align: middle;">Copyright © 2021 National University Health System. All Rights Reserved.<br></p>
            </td>
        </tr>
      </tbody>
    </table>
`;

  return (
    <div className="container justify-content-center text-center pt-5 ">
      <div style={{ marginTop: "15px" }}></div>
      <div dangerouslySetInnerHTML={{ __html: contentHtml1 }} />
      <div style={{ marginBottom: "50px" }}></div>
    </div>
  );
};

export default ViewEmail;
