import React, { useState, useEffect } from "react";
import axios from "axios";

import { Base_Url, webHost, webHostUi } from "../../../routes/Base_Url";

import { PledgeCard } from "../components/pledgeCard";
import { BigPledgeCard } from "../components/BigPledgeCard";
import PledgeForm from "../components/pledgeForm";
import { PledgeProgress } from "../components/pledgeProgressBar";
import { withMedia } from "react-media-query-hoc";
import PledgeRibbonsForMobile from "../components/PledgeRibbonForMobile";
import PledgeRibbonsForTablet from "../components/PledgeRibbonsForTablet";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import ReactGA from "react-ga";
import validator from "validator";

const PledgeContainer = (props) => {
  const { media } = props;
  const [menuVisible, setMenuVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [recipientName, setRecipientName] = useState("");
  const [senderName, setSenderName] = useState("");
  const [cancerName, setCancerName] = useState(null);

  const [message, setMessage] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [warning, setWarning] = useState(false);
  const [shareImage, setShareImage] = useState(null);
  const [complete, setComplete] = useState(false);
  const [winner, setWinner] = useState(0);
  const [loading, setLoading] = useState(false);
  const [finalImage, setFinalImage] = useState(null);
  const [showThankU, setShowThankU] = useState(false);
  const [downloadImgUrl, setDownloadImgUrl] = useState(null);
  const [emailWarning, setEmailwarning] = useState("");
  const [shareApp, setShareApp] = useState(null);
  const [email, setEmail] = useState("");

  const [selected, setSelected] = useState(false);
  const [nextOfStep1, setNextOfStep1] = useState(selected ? true : false);

  const ribbonName = imgUrl
    ? imgUrl.split("/")[imgUrl.split("/").length - 1]
    : "ribbonname";

  const resetContainer = () => {
    setMenuVisible(false);
    setStep(1);
    setRecipientName("");
    setSenderName("");
    setCancerName(null);
    setMessage("");
    setImgUrl(null);
    setWarning(false);
    setShareImage(null);
    setComplete(false);
    setWinner(0);
    setLoading(false);
    setFinalImage(null);
    setShowThankU(false);
    setSelected(false);
    setNextOfStep1(false);
  };

  const _handleEdit = () => {
    setStep(step == 3 ? 2 : 1);
  };
  const _handleSelect = () => {
    setMenuVisible(!menuVisible);
  };

  const _handleReview = (e) => {
    e.preventDefault();

    if (recipientName && senderName && message && imgUrl) {
      setStep(2);
      setWarning(false);
    } else {
      setWarning(true);
    }
  };

  const _handleConfirm = (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "User",
      action: "Pledged a ribbon",
    });

    if (window.navigator.userAgent.match("CriOS")) {
      const myNode = document.getElementById("my-big-node");
      const wrapper = document.getElementById("big-card-wrapper");
      const url = `${Base_Url}uploadImage`;

      wrapper.style.removeProperty("display");

      setLoading(true);

      function openImage() {
        domtoimage.toPng(myNode).then((base64data) => {
          wrapper.style.display = "none";

          axios
            .post(url, { ribbon: base64data })
            .then((res) => {
              const shareImg = res.data.payload;

              setLoading(false);
              setStep(3);
              setDownloadImgUrl(shareImg);
            })
            .catch((err) => console.log(err));
        });
      }

      domtoimage.toPng(myNode).then(() => {
        openImage();
      });
    } else {
      setStep(3);
    }

    // setup gif
    // const gif = new window.GIF({
    //   workers: 1,
    //   quality: 10,
    //   background: "#ffffff00",
    //   transparent: "#ffffff00",
    //   width: media.desktop ? 290 : media.tablet ? 110 : 260,
    //   height: media.desktop ? 300 : media.tablet ? 120 : 270
    // });

    // let count = 0
    // const delayMs = 80;
    // let mInterval = setInterval(async () => {
    //   count = count + delayMs
    //   if (count === 4000) {
    //     if (mInterval) {
    //       await clearInterval(mInterval)
    //       mInterval = null
    //       gif.on('finished', saveGIf);
    //       gif.render();
    //     }
    //   } else if (count < 4000) {
    //     const dataUrl = await domtoimage.toPng(myNode)
    //     const imageElement = document.createElement("IMG")
    //     imageElement.setAttribute("src", dataUrl);
    //     await gif.addFrame(imageElement, { delay: delayMs });
    //   }
    // }, delayMs);

    axios
      .post(`${Base_Url}sharecount`)
      .then((data) => {
        console.log("sharecountdata =>", data);
      })
      .catch((error) => {
        console.log("error is=>", error);
      });

    axios
      .post(`${Base_Url}luckydrawcount`)
      .then((data) => {
        // console.log('lucky data is=>', data);
        setWinner(data.data.lucky);
      })
      .catch((error) => {
        console.log("error is=>", error);
      });
  };

  // const saveGIf = (blob) => {
  //   console.log("Trigger finished. Going to save to server!");
  //   saveAs(blob, "ribbon.gif");
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = function () {
  //     const base64data = reader.result;
  //     const url = `${Base_Url}uploadImage`;
  //     axios.post(url, { ribbon: base64data })
  //       .then(res => {
  //         const shareImg = res.data.payload;
  //         setShareImage(shareImg);
  //         setLoading(false);
  //       })
  //       .catch(err => console.log(err));
  //     setLoading(false);
  //     setStep(3);
  //   }
  // }
  const downloadImg = () => {
    const myNode = document.getElementById("my-big-node");
    const wrapper = document.getElementById("big-card-wrapper");

    wrapper.style.removeProperty("display");

    function openImage(i) {
      domtoimage.toPng(myNode).then((base64data) => {
        wrapper.style.display = "none";

        let a = document.createElement("a");
        a.href = `${base64data}`;
        a.download = "NCIS Ribbon Challenge 2021.png";
        a.type = "image/png";
        a.click();
      });
    }

    domtoimage.toPng(myNode).then((base64data) => {
      openImage();
    });
  };

  const _handleSelectOption = (e) => {
    setMessage(e);
    setWarning(false);
  };

  const _handleTextChange = (e) => {
    if (e.target.id === "recipient") {
      setRecipientName(e.target.value);
      setWarning(false);
    } else {
      setSenderName(e.target.value);
      setWarning(false);
    }
  };

  const _handleRibbonClick = (state) => {
    setMenuVisible(state);
  };

  const _handleImage = (img, cancer) => {
    setImgUrl(img);
    setCancerName(cancer);
  };

  const _handleShare = () => {
    setComplete(true);
    setStep(3);
    setShowThankU(true);
  };

  const shareMail = (e) => {
    e.preventDefault();
    if (email == null || email == "")
      return setEmailwarning(" Please enter recipient email!");

    if (validator.isEmail(email)) {
      setEmailwarning(null);
      setLoading(true);
      setShowThankU(false);
      setShareApp(null);

      if (email !== null && email !== "") {
        const url = `${Base_Url}uploadImage`;
        const myNode = document.getElementById("cardDivId-email");

        const wrapper = document.getElementById("email-card");

        wrapper.style.display = "block";

        domtoimage.toPng(myNode).then((base64data1) => {
          domtoimage.toPng(myNode).then((base64data) => {
          wrapper.style.display = "none";

          axios.post(url, { ribbon: base64data }).then((res) => {
            const shareImg = res.data.payload;
            setEmailwarning(false);
            setEmail("");

            const contentHtml1 = `
            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" style="background-color:#ffffff; padding-top:10px; max-width: 600px;">
                <tr>
                  <td align="center" style="height:auto;">
                    <span style="padding: 10px; font-size: 14px;">You've received a pledge! To read the online version of this email, click <a href='${webHostUi}/view_email/${shareImg}' style='font-weight:bold;'>HERE</a></span>
                    <img src="${webHost}/${shareImg}" alt="" style="width: 600px; height: auto;">
                  </td>
                </tr>
                <tr>
                  <td valign="top" style="padding:15px 50px 10px 50px; line-height:0px;">
                    <p style="font-family: Open Sans, sans-serif; text-align: center; color: #281f57;font-size: 16px;line-height:25px; margin-bottom:8px"><b>You have received a pledge to raise the awareness for cancer. Since 2018, the NCIS Ribbon Challenge has been raising awareness and public education on cancer. You can join in the <span style="color: #fd794f;">NCIS Ribbon Challenge 2021</span> too, by clicking on the link and pledging a ribbon to a loved one.</b></p>
                  </td>
                </tr>
                <tr align="center" valign="top" style="padding:5px; border:0px; line-height:0px;">
                  <td align="center" width="600px" bgcolor="#ffffff" valign="middle" style="padding:10px; border:0px; line-height:0px;">
                    <p style="background-color : #fd794f; border-radius: 20px; font-style: normal; font-weight: bold; letter-spacing: normal; text-align: center; padding: 5px 10px 5px 10px; margin: 0; font-size: 17px; line-height: 27px; width: 200px; font-family:Open Sans, sans-serif;" align="center"><a href="https://ncisribbonchallenge.sg/?utm_source=TYEmail&utm_medium=email&utm_campaign=ribbonchallenge2021" style="color:#ffffff; text-decoration:none; vertical-align: middle;" target="_blank">Pledge A Ribbon</a></p><p></p>
                  </td>
                </tr>
                <tr>
                    <td align="center" bgcolor="#e7e1e7" style="padding-top:10px; padding-bottom: 10px; border:0px; line-height:0;" width="100%" height="30px">
                      <p style="font-family:Open Sans, sans-serif; text-align:center; font-size:11px; color:#333; line-height: 20px; vertical-align: middle;">Copyright © 2021 National University Health System. All Rights Reserved.<br></p>
                    </td>
                </tr>
            </table>
            `;

            const bodyData = {
              receiveEmail: email,
              subjectText: "You’ve received a pledge!",
              contentHtml: contentHtml1.replace(/\s+/g, " ").trim(),
            };
            fetch(Base_Url + "share-email", {
              method: "post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(bodyData),
            })
              .then((res) => res.json())
              .then((d) => resetContainer())
              .catch((error) => {
                console.error(error);
                resetContainer();
              });
          });
        });
      });
      }
    } else {
      setEmailwarning("Enter valid Email!");
    }
  };

  let background = media.desktop
    ? "/Desktop_PledgeARibbonPage.jpg"
    : media.tablet
    ? "url(/PledgeRibbonTablet.svg)"
    : "/PledgeBgMobo.png";

  return (
    <>
      <div style={{ overflowY: "hidden" }}>
        {loading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100vh",
              zIndex: 2000,
            }}
          ></div>
        )}
        <div style={{ opacity: loading ? 1 : 0 }} className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div
          className="d-flex align-self-center "
          style={{
            backgroundImage: media.tablet
              ? "url(/PledgeRibbonTablet.svg)"
              : "none",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            minHeight: "100vh",
            paddingTop: 58,
            backgroundColor: media.tablet && "#e6dfe6",
          }}
        >
          {(media.mobile || media.desktop) && (
            <div id="testsvg" style={{ width: "100%", background: "#e6dfe6" }}>
              <div className="" style={{ width: media.desktop && "83%" }}>
                <img
                  className="img-fluid  w-100"
                  src={
                    media.desktop
                      ? "/Desktop_PledgeARibbonPage.png"
                      : "/PledgeBgMobo.png"
                  }
                  alt="bg-svg"
                  style={{ minHeight: "100vh" }}
                />
              </div>
            </div>
          )}

          <div
            className={`${
              media.tablet ? "col-12" : "col-md-12 col-sm-12"
            } pt-4 px-4`}
            style={{ overflowY: "hidden", overflowX: "hidden" }}
          >
            {media.desktop ? (
              <div className="row px-0 ">
                <div
                  className="d-flex justify-content-start col-4 align-self-start px-0"
                  style={{ textAlign: "center", width: 380 }}
                >
                  <div
                    className="pt-3 pb-1 px-2"
                    style={{
                      marginTop:
                        window.innerWidth > 1500
                          ? "4%"
                          : media.tablet
                          ? "13%"
                          : "5%",
                      marginLeft: "2%",
                      position: (media.tablet || media.desktop) && "fixed",
                    }}
                  >
                    <PledgeCard
                      recipientName={recipientName}
                      senderName={senderName}
                      message={message}
                      media={media}
                      step={step}
                      _handleImage={_handleImage}
                      imgUrl={imgUrl}
                      cancer={cancerName}
                      finalImage={finalImage}
                    />
                    <BigPledgeCard
                      recipientName={recipientName}
                      senderName={senderName}
                      message={message}
                      media={media}
                      step={step}
                      imgUrl={imgUrl}
                      _handleImage={_handleImage}
                      cancer={cancerName}
                      finalImage={finalImage}
                    />
                  </div>
                  <div
                    className="px-2"
                    style={{
                      position: "fixed",
                      fontSize: media.tablet ? 24 : media.mobile ? 22 : 20,
                      fontWeight: "bold",
                      top: "80%",
                      // bottom: 105,
                      lineHeight: 1,
                      color: "#d8cad8",
                      zIndex: -1,
                      textAlign: "left",
                      marginLeft: "2%",
                    }}
                  >
                    NCIS Ribbon Challenge 2021
                    <p
                      className="py-2 text-white"
                      style={{
                        fontSize: media.tablet ? 20 : media.mobile ? 18 : 16,
                      }}
                    >
                      Together, We Fight Cancer!
                    </p>
                  </div>
                </div>
                <div
                  className="col-8 pt-4 justify-content-center mx-0"
                  style={{ flex: 1 }}
                >
                  <div className="d-flex justify-content-center mx-0 px-0">
                    <div className="col-7 px-0">
                      <PledgeProgress step={step} media={media} />
                    </div>
                  </div>

                  <PledgeForm
                    step={step}
                    _handleConfirm={_handleConfirm}
                    _handleEdit={_handleEdit}
                    _handleTextChange={_handleTextChange}
                    _handleReview={_handleReview}
                    _handleSelect={_handleSelect}
                    _handleSelectOption={_handleSelectOption}
                    _handleShare={_handleShare}
                    setCancerName={setCancerName}
                    recipientName={recipientName}
                    message={message}
                    senderName={senderName}
                    cancerName={cancerName}
                    menuVisible={menuVisible}
                    _handleRibbonClick={_handleRibbonClick}
                    media={media}
                    complete={complete}
                    _handleImage={_handleImage}
                    warning={warning}
                    shareImage={shareImage}
                    winner={winner}
                    imgUrl={imgUrl}
                    setShowThankU={setShowThankU}
                    showThankU={showThankU}
                    setLoading={setLoading}
                    resetContainer={resetContainer}
                    downloadImg={downloadImg}
                    shareMail={shareMail}
                    emailWarning={emailWarning}
                    shareApp={shareApp}
                    email={email}
                    setEmailwarning={setEmailwarning}
                    setShareApp={setShareApp}
                    setEmail={setEmail}
                  />
                </div>
              </div>
            ) : media.tablet ? (
              <div
                className="d-flex justify-content-center align-self-center pt-1"
                style={{
                  marginTop:
                    window.innerWidth > 780 && media.tablet ? "20%" : "19%",
                }}
              >
                <PledgeRibbonsForTablet
                  step={step}
                  media={media}
                  _handleConfirm={_handleConfirm}
                  _handleEdit={_handleEdit}
                  _handleTextChange={_handleTextChange}
                  _handleReview={_handleReview}
                  _handleSelect={_handleSelect}
                  _handleShare={_handleShare}
                  _handleSelectOption={_handleSelectOption}
                  setImgUrl={_handleImage}
                  imgUrl={imgUrl}
                  shareImage={shareImage}
                  recipientName={recipientName}
                  message={message}
                  senderName={senderName}
                  menuVisible={menuVisible}
                  recipientName={recipientName}
                  senderName={senderName}
                  message={message}
                  complete={complete}
                  _handleRibbonClick={_handleRibbonClick}
                  shareImage={shareImage}
                  cancer={cancerName}
                  setCancerName={setCancerName}
                  winner={winner}
                  warning={warning}
                  setShowThankU={setShowThankU}
                  showThankU={showThankU}
                  downloadImg={downloadImg}
                  cancer={cancerName}
                  finalImage={finalImage}
                  setLoading={setLoading}
                  resetContainer={resetContainer}
                  downloadImgUrl={downloadImgUrl}
                  shareMail={shareMail}
                  emailWarning={emailWarning}
                  shareApp={shareApp}
                  email={email}
                  setEmailwarning={setEmailwarning}
                  setShareApp={setShareApp}
                  setEmail={setEmail}
                  selected={selected}
                  nextOfStep1={nextOfStep1}
                  setSelected={setSelected}
                  setNextOfStep1={setNextOfStep1}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center w-100">
                <div className="">
                  <div className="d-flex pt-1 justify-content-center">
                    <div className="col-8">
                      <PledgeProgress step={step} media={media} />
                    </div>
                  </div>
                  <PledgeRibbonsForMobile
                    step={step}
                    media={media}
                    _handleConfirm={_handleConfirm}
                    _handleEdit={_handleEdit}
                    _handleTextChange={_handleTextChange}
                    _handleReview={_handleReview}
                    _handleSelect={_handleSelect}
                    _handleShare={_handleShare}
                    _handleSelectOption={_handleSelectOption}
                    setImgUrl={_handleImage}
                    setCancerName={setCancerName}
                    imgUrl={imgUrl}
                    recipientName={recipientName}
                    message={message}
                    senderName={senderName}
                    menuVisible={menuVisible}
                    recipientName={recipientName}
                    senderName={senderName}
                    message={message}
                    _handleRibbonClick={_handleRibbonClick}
                    shareImage={shareImage}
                    complete={complete}
                    cancer={cancerName}
                    winner={winner}
                    warning={warning}
                    setShowThankU={setShowThankU}
                    showThankU={showThankU}
                    downloadImg={downloadImg}
                    cancer={cancerName}
                    finalImage={finalImage}
                    downloadImgUrl={downloadImgUrl}
                    setLoading={setLoading}
                    resetContainer={resetContainer}
                    shareMail={shareMail}
                    emailWarning={emailWarning}
                    shareApp={shareApp}
                    email={email}
                    setEmailwarning={setEmailwarning}
                    setShareApp={setShareApp}
                    setEmail={setEmail}
                    selected={selected}
                    nextOfStep1={nextOfStep1}
                    setSelected={setSelected}
                    setNextOfStep1={setNextOfStep1}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Email Image */}
      <div id="email-card" style={{ display: "none" }}>
        <div
          id="cardDivId-email"
          style={{
            zIndex: -10,
            marginBottom: 80,
            boxShadow: "0px 0px 8px 0px #a5a5a5",
            fontSize: "16px",
            position: "relative",
            width: "600px",
            height: "600px",
            backgroundSize: "100% 100%",
            top: "0",
          }}
        >
          <img
            src={`/assets/images/EmailShareCard.png`}
            alt="emailShareCard"
            style={{
              // display: "block",
              width: "600px",
              height: "600px",
              position: "absolute",
              // height: "100%",
              zIndex: "-1",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "60px",
              right: "40px",
              textAlign: "start",
              color: "white",
            }}
          >
            <div style={{ paddingBottom: "16px" }}>Dear {recipientName},</div>
            <div style={{ paddingBottom: "16px" }}>{message}</div>
            <div>Love {senderName}</div>
          </div>
          <img
            src={`/assets/images/ribbons/${ribbonName}`}
            alt="selected-ribbons"
            style={{
              position: "absolute",
              width: "150px",
              height: "150px",
              right: "83px",
              top: "72%",
              zIndex: 3,
            }}
          />
          <svg
            viewBox="-12 5 128 69"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              width: "150px",
              height: "150px",
              right: "90px",
              top: "68%",
              transform: "rotate(0deg)",
            }}
          >
            <path
              id="curve-path"
              fill="none"
              stroke="red"
              stroke-width="0"
              d="M5.47387 48.2344C10.5 -16 107 -10.5 108.474 48.2344"
            />

            {
              ( cancerName == "Nasopharyngeal" ? 
              <text font-size="9" font-weight="600" fill="white">
                <textPath href="#curve-path" startOffset={(55 - (cancerName && cancerName.length + parseInt(cancerName.length / 3) ) - 18 * 2) + "%"}>
                    {cancerName} {((cancerName === "Melanoma" || cancerName === "Lymphoma") ? "" : " Cancer")}
                </textPath>
              </text>
              :
              <text font-size="11" font-weight="600" fill="white">
                <textPath href="#curve-path" startOffset={(55 - (cancerName && cancerName.length + parseInt(cancerName.length / 3) ) - 18 * 2) + "%"}>
                    {cancerName} {((cancerName === "Melanoma" || cancerName === "Lymphoma") ? "" : " Cancer")}
                </textPath>
              </text>
              )
            }
            
          </svg>
        </div>
      </div>
    </>
  );
};
export default withMedia(PledgeContainer);
