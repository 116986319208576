import React from "react";
import Mamogram from "../../../assets/images/Mamogram.png";
import FIT from "../../../assets/images/FIT.png";
import CancerEducationTalks from "../../../assets/images/CancerEducationTalks.png";
import drKumarakulasinghe from "../../../assets/PeopleImage/drKumarakulasinghe.png";
import drLimYiWan from "../../../assets/PeopleImage/drLimYiWan.png";
import drLimSiewEng from "../../../assets/PeopleImage/drLimSiewEng.png";
import drOuGuanWei from "../../../assets/PeopleImage/drOuGuanWei.png";
import drLingSiJing from "../../../assets/PeopleImage/drLingSiJing.png";
import drHuangYiQing from "../../../assets/PeopleImage/drHuangYiQing.png";
import eventPic from "../../../assets/images/event.jpg";
import { NCIS_Button } from "../../../tools/NCIS_Button";

import moment from 'moment';
import font from "../../../app/config/font";
export const Event = (props) => {
    const { media } = props;
    const [w, setW] = React.useState(null)
    React.useEffect(() => {
        setW(window.innerWidth)
        window.addEventListener("resize", () => {
            // console.log(window.innerWidth)
            setW(window.innerWidth)
        })
    }, [])

    if(!w) return null;
    
    const sSize =  w >= 1920 ? "xxl" 
        : w >= 1600 ? "xl"
        : w >= 1366 ? "lg"
        : w >= 1000 ? "md"
        : w >= 700 ? "sm" : "sm"

    return (
        <>
        {["md", "sm"].includes(sSize) && <div style={{ height: 40 }}></div> }
        <div className="pb-5 text-white " 
            style={{ 
                backgroundColor:"#271f57",
                backgroundImage: media.mobile ? "url(/assets/images/EventMobile.png)" : "url(/assets/images/EventDesktop.png)",
                backgroundRepeat: "no-repeat",
                backgroundSize: media.mobile ? "cover" : "100%",
                backgroundPositionY: media.mobile ? 0 : "-82px",
                paddingTop: sSize==="xxl" ? w* 0.15/**0.33*/ /*660*/ : sSize==="xl" ? 300 : sSize==="lg" ? 220 : sSize==="md" ? 160 : 20+w*0.1,
                overflow: "auto",
                fontSize: font.mobileBody,
                lineHeight: 1.5,
                paddingLeft: sSize==="xxl" ?  w*0.1 : sSize==="xl" ? 160 : sSize==="lg" ? 140 : sSize==="md" ? 120 : media.mobile ? "" : 25,
                paddingRight: 10,
            }}> 
            <div className={`${media.mobile ? "px-3" : "px-3"}`}
        style={{
          fontSize: media.tablet ? font.tabletHeading2 : media.mobile ? font.tabletHeading2 : 52,
          fontWeight: "bold",
          // paddingBottom: (media.cusHeight_600 || media.mobile) ? "40px" : "70px",
          lineHeight:media.mobile || media.tablet ? "20px" : "47px",
          color: '#d8cad8'
        }}
      >
        NCIS Ribbon 
        <br />
        Challenge 2021
      </div>
      
      <div
        className={`${media.mobile ? "pt-3 px-3" : "pt-3 px-3"}`}
        style={{
          fontSize: media.tablet ? 20 : media.mobile ? 12 : 28,
          fontWeight: 600,
        //   paddingBottom: media.cusHeight_600 || media.mobile ? "20px" : "40px",
          lineHeight: "26px",
        }}
      >
        Together, We Fight Cancer!<br/>
        22 Feb - 31 Mar
      </div>
      
            {/* <div style={{ position: "absolute", left: 50, top: 150, color: "white", padding: 20, background: "green", fontSize: "2em"}}>{w } {sSize}</div> */}
            <div className="d-flex px-2" style={{ paddingTop :media.mobile ? 30 : media.tablet ? 50 : 100 }}>            
               
                <div className="px-3 text-dark" 
                    style={{ position: "absolute", 
                    left: "58%", 
                    // top: sSize==="xxl" ? 700+(w-1920)*0.5 :  sSize==="xl" ? 600+(w-1600)*0.5 : sSize==="lg" ? 480+(w-1366)*0.5 : sSize==="md" ? 340+(w-1000)*0.5 : 200+(w-520)*0.5}}>
                    top: sSize==="xxl" ? 500+(w-1920)*0.5 :  sSize==="xl" ? 400+(w-1600)*0.5 : sSize==="lg" ? 380+(w-1366)*0.5 : sSize==="md" ? 320+(w-1000)*0.5 : 175+(w-520)*0.5}}>

                    {/* <div className="" style={{  lineHeight : media.mobile && "18px"}}> 
                        <span className="" style={{ fontSize: ["md", "sm"].includes(sSize) ? "1.2em" : "2em", color: "#271f57", fontWeight:"bold"}}>6 March 2021</span>
                        <span className="d-block" style={{ fontSize: ["md", "sm"].includes(sSize) ? "0.9em" : "1.8em", color: "#271f57", fontWeight:"bold" }}>10am - 11.30pm (English)</span>
                        <span className="d-block" style={{ fontSize: ["md", "sm"].includes(sSize) ? "0.9em" : "1.8em", color: "#271f57", fontWeight:"bold" }}>2pm - 3.30pm (Chinese)</span>

                    </div> */}
                                        
                </div>
            </div>
            <div className="text-left d-flex flex-column justify-content-center px-2" style={{paddingTop: '2rem'}}>
                <div className="d-flex px-1 justify-content-between text-left">
                    <div className="" style={{width: '100%'}}> 
                        <div className="px-1" style={{ fontSize: font.eventTitle, letterSpacing: 1.3 }}>
                            <b>Event Highlights:</b>
                        </div>

                        <div className="mt-4 m-3">
                                {
                                    media.desktop?
                                    <img src={eventPic} style={{ width: '90%', height: "auto" }} />
                                    :
                                    <img src={eventPic} style={{ width: '100%', height: "auto" }} />
                                }
                        </div>
                        <hr />
                        <div className="py-3 d-flex flex-wrap flex-md-nowrap px-2"  style={{ maxWidth: 1200 }}>     
                            <img src={FIT} className="pe-3 pb-2" style={{ height: 60 }} />
                                <div className="flex-grow-1" style={{  }}>  
                                    <b style={{ fontSize:20,lineHeight : '26px' }}>FIT (Faecal Immunochemical Test) Kit Distribution</b>
                                    <div className="pt-3" style={{ textShadow: "0px 0px 2px gray",fontSize : media.desktop ? font.desktopBody : media.mobile ? font.mobileBody : font.tabletBody, lineHeight: media.mobile ? "22px" : "26px" }}>
                                        The Faecal Immunochemical Test (FIT) is a preliminary test that detects the presence of small amounts of blood in faeces.
                                        {/* Colorectal polyps and cancers can be detected by the traces of blood that are bled into the colon.   */}
                                    </div>
                                    <div className="pt-2" style={{ textShadow: "0px 0px 2px gray",fontSize : media.desktop ? font.desktopBody : media.mobile ? font.mobileBody : font.tabletBody, lineHeight: media.mobile ? "22px" : "26px" }}>
                                    If you are a Singaporean or PR aged 50 years and above*,&nbsp; you can request for a FIT Kit at no cost.&nbsp; *Term and conditions apply
                                        {/* If you are a Singaporean or PR aged 50 years and above, you can request for a FIT Kit at no cost. */}
                                    </div>
                                    <div className='pt-4 d-flex justify-content-start'>
                                        <a href="https://bit.ly/3aNzFzF" target="_blank">
                                        <NCIS_Button
                                            text={"Request for a FIT Kit"}
                                            onClick={""}
                                            fontSize={w > 1000 ? 16 : 14}
                                            className=""
                                            fontSize={16}         
                                            width={220}                                   
                                        />
                                        </a>
                                    </div>
                                </div>                                
                        </div>   
                        <hr />
                        <div className="py-3 px-2 d-flex flex-wrap flex-md-nowrap" style={{ maxWidth: 1200 }}>
                            <div className="">
                                <img src={Mamogram} className="pe-3 pb-2" style={{ height: 60 }} />
                            </div>
                            <div className="flex-grow-1" style={{  }}>            
                                <b style={{ fontSize: 20,lineHeight : '26px' }}>Mammogram Screening </b>
                                {/* <div className="pt-3" style={{ textShadow: "0px 0px 2px gray",fontSize : media.desktop ? font.desktopBody : media.mobile ? font.mobileBody : font.tabletBody, lineHeight: media.mobile ? "22px" : "26px" }}>
                                    Ladies, if you above 50 years old, and have not done a mammogram screening, register for one now and have a peace of mind.<br/>
                                </div> */}
                                <div className="pt-2" style={{ textShadow: "0px 0px 2px gray",fontSize : media.desktop ? font.desktopBody : media.mobile ? font.mobileBody : font.tabletBody, lineHeight: media.mobile ? "22px" : "26px" }}>
                                The mammobus will be at NUH Medical Centre Level 3 Drop-Off Point to carry out mammogram screening for ladies aged 50 and above*. Pre-registration is required. Please call 67737888 or email to
                                &nbsp;<a className="text-info" href="mailto:cancerscreening@nuhs.edu.sg">cancerscreening@nuhs.edu.sg</a>&nbsp;
                                    to book your slot. *Terms and conditions apply.

                                    {/* The mammobus will be at NUH Main Building Lobby B to carry out mammogram screening. Pre-registration is required. Please call 6773 7888 or click on the button below to book your slots. Terms apply. */}
                                </div>                                
                                <div className='pt-4 d-flex justify-content-start'>
                                    <a href="mailto:cancerscreening@nuhs.edu.sg">
                                    <NCIS_Button
                                        text={"Book your slot"}
                                        width={220}
                                        onClick={""}
                                        fontSize={w > 1500 ? 16 : 14}
                                        fontSize={16}         
                                            width={220}  
                                    /></a>
                                </div>                                
                            </div>                            
                        </div>   
                        <div className='pt-4 pb-4 px-1' style={{ fontSize : media.desktop ? font.desktopBody : media.mobile ? font.mobileBody : font.tabletBody, lineHeight: media.mobile ? "22px" : "26px" }}
                            onClick={()=>{
                                var win = window.open("https://www.ncis.com.sg/events/Pages/NCIS-Ribbon-Challenge.aspx","_blank");
                                win.focus();
                              }}
                            >
                                For more information about this event, please click <a className="text-info" href="" target="_blank">here. </a>
                            {/* <hyperlink to https://www.ncis.com.sg/events/Pages/NCIS-Ribbon-Challenge.aspx> */}
                        </div>                      
                                                                                           
                    </div>                                        
                </div>                                                                            
            </div>
        </div>
        </>
    )}